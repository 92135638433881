<template>
  <div>
    <div class="text-header mb-10">Enter your code</div>
    <div class="text-description">We sent you a one-time code by SMS.</div>
    <v-otp-input label="Enter your code" v-model="otp" length="6"></v-otp-input>
    <v-row justify="center" class="col-12 my-1">
      <div id="recaptcha-container"></div>
    </v-row>
    <v-btn
      class="sign-up-button my-4 white--text mb-4"
      rounded
      color="#00b08d"
      large
      block
      :loading="isLoading"
      :disabled="isLoading || !otp || otp.length != 6"
      @click="signUp"
      >Create account
    </v-btn>

    <div class="text-center my-4">Didn’t receive your code?</div>
    <div class="text-center" v-if="timer != 0">Resend code ({{ timer }})</div>
    <div class="text-center" v-if="timer == 0">
      <v-btn
        text
        color="primary"
        @click="signUpOrSignInToFirebaseByPhoneNumber()"
        >Resend</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AuthMixin from "@/mixins/AuthMixin.vue";

export default {
  mounted() {
    // set the app bar title
    this.setCurrentAppBarTitle("Phone Sign In");

    //? if captcha verification lost, push back to sign in page
    if (!window.confirmationResult) {
      this.$router.push({ name: "SignIn" });
    }
    //? start resend otp counter
    this.startOTPResendCounter();
    this.initReCaptcha();
  },
  mixins: [AuthMixin],
  data() {
    return {
      countDownTimerHandler: null,
      timer: 10,
      otp: null,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions([
      "submitOTP",
      "signUpOrSignInToFirebaseByPhoneNumber",
      "initReCaptcha",
    ]),
    ...mapMutations(["setErrorSnackbarMessage", "setCurrentAppBarTitle"]),
    async signUp() {
      this.isLoading = true;
      try {
        await this.submitOTP(this.otp);
        await this.afterSignIn();
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    startOTPResendCounter() {
      let that = this;
      this.countDownTimerHandler = setInterval(function () {
        that.timer = that.timer - 1;
        if (that.timer <= 0) {
          clearInterval(that.countDownTimerHandler);
        }
      }, 1000);
    },
  },
};
</script>
